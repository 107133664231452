(function($) {

    $('[data-mesh-form]').ajaxForm({
        url: mesh_wp_config.wp_admin_ajax,
        data: {action: 'spice_form'},
        dataType: 'json',
        beforeSubmit: function(data, $form) {
            var $btn = $form.find('button');
            //$btn.data('label', $btn.html()).html('Please wait...');
            $btn.prop('disabled', true);
        },
        success: function(data, status, jqxhr, $form) {
            var $btn = $form.find('button');
            $btn.html($btn.data('label'))
                .prop('disabled', false);


            $form.find('.error-message, .success-message').detach();
            $form.find('.has-error').removeClass('has-error');

            if(data.success === false) {
                var instanceId = $form.find('input[name="_pc_spice_form_instance"]').val();
                var formName = $form.find('input[name="_pc_spice_form"]').val();

                $.each(data.errors, function(key, value) {
                    var $error = $('<div class="error-message"><span class="icon-cancel"></span><span class="text"></span></div>');
                    if(key === '_form') {
                        $.each(value, function(i, err) {
                            $error.find('.text').text(err);
                            $form.prepend($error);
                        });
                    } else {
                        $error.find('.text').text(value);
                        $form.find("[name='" + instanceId + "[" + key + "]']")
                            .after($error)
                            .closest('.form-group')
                            .addClass('has-error');

                    }
                });
                var offset = $form.find('.has-error').eq(0).offset().top - 100;
                $('html,body').animate({'scrollTop': offset + 'px'}, 1000, 'swing');
            } else {
                var success_message =  'Thank you for your enquiry. We will get back to you as soon as we can.';
                if($form.data('successMessage')) {
                    success_message = $form.data('successMessage');
                }
                $form.append(
                    $('<div class="success-message"><span class="icon-checkmark"></span><span class="text">' + success_message + '</span></div>')
                );
                if(window.ga) {
                    console.log('ga(' +  $form.find('[name=_pc_spice_form]').val() + ')');
                    ga('send', 'event', 'Forms', 'Submission', $form.find('[name=_pc_spice_form]').val());
                }
            }
        }
    });
})(jQuery);