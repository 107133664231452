/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  $('input,textarea').placeholder();

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // workaround for strange ios safari quirk/bug
        // see http://www.quirksmode.org/blog/archives/2014/02/mouse_event_bub.html
        $('.sitenav').on('click', function() {});

        $('html').on('click', 'body.nav-visible, .sitenav--trigger', function(e) {
          if($(e.currentTarget).is(".sitenav--trigger")) {
            e.preventDefault();
          }

          var toggleMenu = function() {
            if ($(e.target).closest('.sitenav--items').length === 0 || $(e.target).is('.sitenav--trigger')) {
              e.preventDefault();
              e.stopPropagation();
              console.log('click');
              $('body').toggleClass('nav-visible');
            }
          };
          if($('.dropdown-form').is(":visible")) {
            $('.dropdown-form').slideUp(600, toggleMenu);
            $('html, body').animate({scrollTop: 0}, 600);
            $('body').removeClass('dropdown-form-visible');
          } else {
            toggleMenu();
          }
        });

        $('html').on('click', '.nav-visible .sitenav .page_item_has_children .icon-chevron-down', function(e) {
          e.preventDefault();
          $(this).parent().find('.children').slideToggle();
        });

        $('.testimonial-reel--items').slick({
          dots: true,
          arrows: false
        });

        $('[data-trigger=booking-form]').click(function(e) {
          $('body').toggleClass('dropdown-form-visible');
          $('.dropdown-form').slideToggle({duration: 800, easing: 'easeInOutQuad'});
          $('html, body').animate({scrollTop: 0}, 600);
        });

        $('.wizard--problem-selector select').on('change blur', function(event) {
          var $selected = $(this).find('option:selected');
          $('.wizard--solution-name').text($selected.data('solutionLabel'));
          $('.wizard--solution-link').attr('href', $selected.data('solutionHref'));
        });

        $('.wizard select').chosen({
          width: '100%',
          disable_search_threshold: 999
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  var a = $.throttle(function(e) {
    var $assessment_tile = $('.nav-tiles--assessment-bg');

      var h = $('.nav-tiles--tile').first().height();
      $assessment_tile.css('height', h);

  }, 100);

  $(window).on('resize', a);
  $(document).on('ready', a);
  $(window).on('load', a);

})(jQuery); // Fully reference jQuery after this point.
